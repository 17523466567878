<template>
  <div>
    <click-to-edit
      v-model="scope.row.scale.point_admin"
      placeholder=""
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.point_admin')"
      type="select"
      select-filterable
      :select-options="[
        {value: 0, name:'0'},
        {value: 50, name:'50'},
        {value: 100, name:'100'},
        {value: 150, name:'150'},
      ]"
      select-option-id="value"
      select-option-name="name"
      select-cast-value-to-int
      @input="saveElementPropChange(scope.row, 'scale', {point_admin:$event})"
    >
    </click-to-edit>
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";
export default {
  name: 'element-point-admin-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      scaleTypesEnum: variables.scaleTypesEnum,
    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
