import { render, staticRenderFns } from "./ElementPointAdminTableColumn.vue?vue&type=template&id=644d09c8&scoped=true"
import script from "./ElementPointAdminTableColumn.vue?vue&type=script&lang=js"
export * from "./ElementPointAdminTableColumn.vue?vue&type=script&lang=js"
import style0 from "./ElementPointAdminTableColumn.vue?vue&type=style&index=0&id=644d09c8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "644d09c8",
  null
  
)

export default component.exports